export default class CartItemsTableController {
    constructor($scope, $http, $location) {
        this.$scope = $scope;
        this.$http = $http;
        this.$location = $location;
    }

    changePeriod(cartItemRowId, period) {
        var tmpForm = document.createElement('form');
        tmpForm.method = 'post';
        tmpForm.action = '/cart/change-period/' + cartItemRowId;

        var tmpToken = document.createElement('input');
        tmpToken.type = 'hidden';
        tmpToken.name = '_token';
        tmpToken.value = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

        tmpForm.appendChild(tmpToken);

        var tmpInput = document.createElement('input');
        tmpInput.type = 'hidden';
        tmpInput.name = 'period';
        tmpInput.value = period;

        tmpForm.appendChild(tmpInput);

        document.body.appendChild(tmpForm);

        tmpForm.submit();
    }
}

CartItemsTableController.$inject = ['$scope', '$http', '$location'];
